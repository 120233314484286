//import logo from './logo.svg';
import './App.css';

import Hero from './components/Hero';
import Header from './components/Header';
import About from './components/About';
import Bts from './components/Bts';
import Skills from './components/Skills';
import Tools from './components/Tools';
import Pro from './components/Pro';
import School from './components/School';
import Portfolio from './components/Portfolio';
import Veille from './components/Veille';
import Contact from './components/Contact';
import ScrollToTopButton from './components/ScrollToTopButton';
import Footer from './components/Footer';

const App = () => {
  return (
    <div className="app-container">
      <Header />
      <div className="content-container">
        <Hero />
        <About />
        <Bts />
        <Skills />
        <Tools />
        <Pro />
        <School />
        <Portfolio />
        <Veille />
        <Contact />
        <ScrollToTopButton />
        <Footer />
      </div>
    </div>
  );
};

export default App;
