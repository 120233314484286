import React from 'react';
import '../css/Veille.css';

const Veille = () => {
    return (
        <section id="services" className="services">
            <div className="container">
                <div className="section-title">
                    <h2>Veille Technologique 📔</h2>
                    <h4>Qu'est-ce que la veille technologique ?</h4>
                    <p>
                        La veille technologique est, pour <b>Steven C. Wheelwright</b> – ancien professeur émérite de
                        l’université de Harvard – "l’ensemble des techniques visant à organiser de façon systématique la
                        collecte, l’analyse, la diffusion et l’exploitation des informations techniques utiles à la sauvegarde
                        et à la croissance des entreprises".
                    </p>
                    <p>
                        Elle permet d’anticiper les innovations et d’évaluer l’impact sur l’environnement et l’organisation.
                    </p>
                    <br />
                    <h4>Les principaux outils de ma veille</h4>
                    <p>
                        J'effectue ma veille technologique avec des newsletters comme <b>Feedly</b>, un agrégateur de flux RSS,
                        et <b>OpenAI.com</b>, la newsletter des professionnels de la Data Science. Je consulte également des
                        sites comme <b>Hitek.fr</b> et <b>Hardware.fr</b>, spécialisés dans l’actualité high-tech.
                    </p>
                    <br />
                    <h4>Sujet de ma veille</h4>
                    <p>
                        Le thème de ma veille technologique est : <b>Quelles sont Les dispositions de l'État français contre la montée des cyberattaques ?</b>
                    </p>
                </div>

                {/* Thème : L'État et la cybersécurité */}
                <div className="row">
                    <center><h4 style={{ color: '#c01010' }}><b>Thème : Les dispositions de l'État français contre la montée des cyberattaques</b></h4></center>
                    <br /><br />

                    <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="600">
                        <div className="icon"><i className="icofont-shield-alt"></i></div>
                        <h4 className="title"><a href="https://www.grantthornton.fr/fr/insights/newsletter/2024/la-newsletter-cybersecurite-de-grant-thornton2/">
                            La cybersécurité en France</a></h4>
                        <p className="description">
                            <center><strong>Septembre 2024</strong></center><br />
                            La cybersécurité est devenue une priorité pour les entreprises françaises, et l'État met en place des
                            stratégies pour renforcer la résilience numérique.
                        </p>
                        <center><h5 className="date">Source : Grant Thornton</h5></center>
                    </div>

                    <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="700">
                        <div className="icon"><i className="icofont-bar-chart"></i></div>
                        <h4 className="title"><a href="https://www.usine-digitale.fr/cybersecurite/#:~:text=30%20Sept.%202024.%20Le%20courtier%20Meilleurtaux">
                            Les dernières actualités sur la cybersécurité</a></h4>
                        <h4 className="title"><a href="https://www.sitegeek.fr/article-technologie/cybercriminalite-france-statistiques-tendances-2024/">Stats</a></h4>
                        <p className="description">
                            <center><strong>30 septembre 2024</strong></center><br />
                            Découvrez les dernières actualités sur les nouvelles menaces en cybersécurité.

                            Augmentation des cyberattaques :

                            En 2024, 37 % des organisations publiques en France ont été ciblées par des cyberattaques, perturbant gravement leurs activités et leurs systèmes informatiques.
                        </p>
                        <center><h5 className="date">Source : L'usine Digitale</h5></center>
                    </div>

                    <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="800">
                        <div className="icon"><i className="icofont-lock"></i></div>
                        <h4 className="title"><a href="https://www.lesechos.fr/thema/articles/lia-au-service-de-la-cybersecurite-2122361">
                            L'IA au service de la cybersécurité</a></h4>
                        <p className="description">
                            <center><strong>Septembre 2024</strong></center><br />
                            L'intelligence artificielle est utilisée pour améliorer la cybersécurité et contrer les menaces futures. Analyse comportementale : Les algorithmes d'IA peuvent repérer des comportements inhabituels dans les systèmes, tels qu'un utilisateur accédant à des données sensibles à des heures inhabituelles.
                            Reconnaissance des schémas de menaces : Grâce au machine learning, les systèmes peuvent s'améliorer en apprenant à partir de menaces précédentes pour détecter des attaques similaires à l'avenir.
                        </p>
                        <center><h5 className="date">Source : Les Echos</h5></center>
                    </div>
                </div>

            </div>
        </section>
    );
};

export default Veille;
